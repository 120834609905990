const dateChangedEventName = 'date-changed';

let disposables = [];

const pikaCfg = {
  firstDay: 1,
  i18n: {
    previousMonth: 'Vorheriger Monat',
    nextMonth: 'Nächster Monat',
    months: [
      'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November',
      'Dezember',
    ],
    weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  },
};

const triggerTpl = '<i class="fa fa-calendar"></i>';

/**
 * @param {Date|null} date
 * @param {HTMLSelectElement[]} selects
 */
function dateToSelects(date, selects) {
  if (date) {
    selects[0].value = '' + (date.getDate());
    selects[1].value = '' + (date.getMonth() + 1);
    selects[2].value = '' + (date.getFullYear());
  } else if (selects[0].options.item(0).value === '0') {
    selects[0].value = '0';
    selects[1].value = '0';
    selects[2].value = '0';
  }
}

/**
 * @param {HTMLElement[]} selects
 * @returns {Date|null}
 */
function dateFromSelects(selects) {
  const day = parseInt(selects[0].value);
  const month = parseInt(selects[1].value);
  const year = parseInt(selects[2].value);
  if (day || month || year) {
    return new Date(year || new Date().getFullYear(), (month || new Date().getMonth() + 1) - 1, day || 1);
  }
  return null;
}

function doInit(Pikaday, div) {
  const selects = [...div.querySelectorAll('select')];
  const reset = div.querySelector('.js-pikaday-selects-reset');

  const field = document.createElement('input');
  field.style.display = 'none';
  div.insertBefore(field, selects[0]);

  const trigger = document.createElement('div');
  trigger.classList.add('u-margin-left-1');
  trigger.classList.add('u-pointer');
  trigger.classList.add('u-inline-block');
  trigger.innerHTML = triggerTpl;
  div.insertBefore(trigger, reset);

  const onDateChanged = (date) => div.dispatchEvent(new CustomEvent(dateChangedEventName, {detail: {date}}));

  let pikaday;
  pikaday = new Pikaday(Object.assign({}, pikaCfg, {
    field,
    trigger,
    position: 'bottom right',
    defaultDate: dateFromSelects(selects),
    setDefaultDate: true,
    onSelect(date) {
      dateToSelects(date, selects);
      onDateChanged(date);
    },
    onOpen() {
      pikaday.el.style.top = 'auto';
      pikaday.el.style.left = 'auto';
    },
  }));
  div.insertBefore(pikaday.el, null);

  const minDateId = div.getAttribute('data-min-date-id');
  const maxDateId = div.getAttribute('data-max-date-id');
  const minDateDiv = minDateId ? document.getElementById(minDateId) : null;
  const maxDateDiv = maxDateId ? document.getElementById(maxDateId) : null;

  if (minDateDiv && maxDateDiv) {
    throw new Error('Please only link in one direction, else there will be recursions.');
  }

  if (minDateDiv) {
    const onMinDateChanged = (event) => {
      const date = event.detail.date;
      pikaday.setMinDate(date);
      const curDate = pikaday.getDate();
      if (!curDate || curDate < date) {
        pikaday.setDate(date);
      }
    };
    minDateDiv.addEventListener(dateChangedEventName, onMinDateChanged);
    disposables.push(() => minDateDiv.removeEventListener(dateChangedEventName, onMinDateChanged));
  }

  if (maxDateDiv) {
    const onMaxDateChanged = (event) => {
      const date = event.detail.date;
      pikaday.setMaxDate(date);
      const curDate = pikaday.getDate();
      if (!curDate || curDate > date) {
        pikaday.setDate(date);
      }
    };
    maxDateDiv.addEventListener(dateChangedEventName, onMaxDateChanged);
    disposables.push(() => maxDateDiv.removeEventListener(dateChangedEventName, onMaxDateChanged));
  }

  const onSelectChanged = () => {
    const date = dateFromSelects(selects);
    pikaday.setDate(date);
    onDateChanged(date);
  };
  selects.forEach((sel) => {
    sel.addEventListener('change', onSelectChanged);
    disposables.push(() => sel.removeEventListener('change', onSelectChanged));
  });

  if (reset) {
    reset.classList.add('u-margin-left-1');
    reset.classList.add('u-pointer');
    reset.classList.add('u-inline-block');
    reset.style.display = '';
    const onReset = () => {
      pikaday.setDate(null);
      dateToSelects(null, selects);
    };
    reset.addEventListener('click', onReset);
    disposables.push(() => reset.removeEventListener('click', onReset));
  }

  disposables.push(() => {
    div.removeChild(field);
    div.removeChild(trigger);
    div.removeChild(pikaday.el);
    pikaday.destroy();
  });
}

export function init() {
  destroy();
  const divs = [...document.querySelectorAll('.js-pikaday-selects')];
  if (divs.length) {
    import('./loaders/pikaday').then((mod) => divs.forEach((div) => doInit(mod.default, div)));
  }
}

export function destroy() {
  disposables.forEach((fn) => fn());
  disposables = [];
}
